import React from 'react';
import { Box } from '@chakra-ui/react';
import Importation from '../components/Importation';

const Import = () => {
  return (
    <Box marginTop={"10px"} border="1px" borderColor="gray.300" borderRadius="2xl" w={'100%'} display={'flex'} flexDirection={'column'} gap={'20px'} p={'10px'}>
      <Importation />
    </Box>
  );
};

export default Import;
