import request from '@request';
import { useMutation, useQueryClient } from 'react-query';
import { useAtom } from 'jotai';
import { agentFunctionnality } from '@store/client/agent';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const postAgent = (data) => {
  return request.post('/users', { ...data, role: 3 });
};

const putAgent = (data) => {
    return request.put(`/agents/${data.id}`, { ...data, password: data.password ? data.password : undefined });
};

export const useUpsertAgent = () => {
  const queryClient = useQueryClient();
  const [{ data }, setAgentTypeFunctionnality] = useAtom(agentFunctionnality);
  const navigate = useNavigate();

  return useMutation({
    mutationFn: data ? putAgent : postAgent,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['agents'] });
      setAgentTypeFunctionnality({ data: null });
      navigate('/agents');
    }
  });
};
