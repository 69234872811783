import MainLayout from '@components/MainLayout';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '@pages/Login';
import Dashboard from '@pages/Dashboard';
import ProtectedRoute from '@components/ProtectedRoute';
import { BrowserRouter } from 'react-router-dom';
import { Auth } from '@context/Auth';
import Tickets from '@pages/Tickets';
import Agents from '@pages/Agents/Agents';
import UpsertAgent from '@pages/Agents/UpsertAgent';
import Rapports from './pages/Rapports/Rapports';


const App = () => {
  return (
    <BrowserRouter>
      <Auth>
        <MainLayout>
          <Routes>
            <Route
              index
              element={
                <ProtectedRoute type="public">
                  <Login />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard"
              element={
                <ProtectedRoute type="protected">
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="tickets"
              element={
                <ProtectedRoute type="protected">
                  <Tickets />
                </ProtectedRoute>
              }
            />
            <Route
              path="agents"
              element={
                <ProtectedRoute type="protected">
                  <Agents />
                </ProtectedRoute>
              }
            />
            <Route
              path="/agents-upsert"
              element={
                <ProtectedRoute type="protected">
                  <UpsertAgent />
                </ProtectedRoute>
              }
            />
            <Route
              path="rapports"
              element={
                <ProtectedRoute type="protected">
                  <Rapports />
                </ProtectedRoute>
              }
            />
          </Routes>
        </MainLayout>
      </Auth>
    </BrowserRouter>
  );
};

export default App;
