import { AspectRatio, Box } from '@chakra-ui/react';
import React from 'react';

const dashboard_url = process.env.DASHBOARD_URL;

const Dashboard = () => {
  return (
    <Box w="100%" p={4}>
      <AspectRatio h="100%" w="100%">
        <iframe
          width="100%"
          height="100%"
          autoFocus
          src={`${dashboard_url}/app/superm-bo/dashboard-665258052e171f03937d42c1?embed=true` }
        />
      </AspectRatio>
    </Box>
  );
};

export default Dashboard;
