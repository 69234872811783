import { toast } from 'react-toastify';
import { Button } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';

import request from '../axios.config';
import { TfiExport } from 'react-icons/tfi';
import { useTranslation } from 'react-i18next';

const DownloadCSVButton = ({ src, options, execDownload }) => {
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [download, setDownload] = useState(false);
  const { path, fetchParams, transformResponse } = options;

  const { t } = useTranslation();
  const csvDownloadRef = useRef(null);

  useEffect(() => {
    const handleWindowFocus = () => {
      if (csvDownloadRef.current) {
        setDownload(false);
        setDataList([]);
        toast.success(t('fileDownload'));
      }
    };

    window.addEventListener('focus', handleWindowFocus);

    return () => {
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, []);

  const handleOnClick = () => {
    const allData = [];
    const pageSize = 1000;
    let page = 1;
    let totalPages = 1;

    function fetchData(resolve, reject) {
      const params = fetchParams({ pagination: { page, pageSize } });
      request
        .get(`${src}${path}?${params}`)
        .then((response) => {
          allData.push(...response.data.data);
          totalPages = response.data.meta.pagination.pageCount;
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          page += 1;
          if (page > totalPages || !totalPages) {
            resolve(allData);
            return;
          }
          fetchData(resolve, reject);
        });
    }

    async function getAllData() {
      return new Promise((resolve, reject) => {
        fetchData(resolve, reject);
      }).then((data) => {
        return transformResponse(data);
      });
    }
    if (!loading) {
      setLoading(true);
      getAllData()
      .then((allDataFinal) => {
          setDataList(allDataFinal);
          setDownload(true);
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.error?.message;
          if (errorMessage?.includes('date')) {
            toast.error(t('errors.invalidDate'));
            return;
          }
          toast.error('Error: Something went wrong');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Button
      size="sm"
      leftIcon={<TfiExport />}
      colorScheme="pink"
      variant="outline"
      disabled={loading}
      isLoading={loading}
      loadingText="Exporting"
      spinnerPlacement='start'
      onClick={handleOnClick}>
      {download &&
        execDownload({
          data: dataList,
          ref: csvDownloadRef
        })}
      {'Export(CSV)'}
    </Button>
  );
};

export default DownloadCSVButton;
