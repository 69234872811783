import axios from "axios";
import { useEffect, useState } from "react";

export const useTypologie = (supervisorValue) => {
	const token = localStorage.getItem("bo-token");
	const [elements, setElements] = useState();
	const [sellingPoints, setSellingPoints] = useState();

	useEffect(() => {
		const getTypologie = async () => {
			try {
				const res = await axios.get(`${process.env.API_PROTOCOL}://${process.env.API_URL}/typologie?populate[elements]=true`, {
					headers: {
					  Authorization: `Bearer ${token}`,
				}});
				setElements(res.data);
				
				// Get the zone from the supervisor value
				const supervisorZone = res.data?.data?.attributes?.elements
					.find(el => el.key === 'supervisor')
					?.types
					.find(sup => sup.name === supervisorValue)
					?.zone;

				// Get selling points with zone filter if available
				const getSellingPoints = async () => {
					try {
						const allSellingPoints = [];
						let page = 1;
						let totalPages = 1;
				
						while (page <= totalPages) {
							const res = await axios.get(`${process.env.API_PROTOCOL}://${process.env.API_URL}/selling-points`, {
								headers: {
									Authorization: `Bearer ${token}`
								},
								params: {
									'pagination[page]': page,
									...(supervisorZone && { 'filters[zone][name]': supervisorZone })
								}
							});
				
							allSellingPoints.push(...res.data.data);
							totalPages = res.data.meta.pagination.pageCount;
							page += 1;
						}
						setSellingPoints(allSellingPoints);
					} catch (error) {
						console.error('Error fetching selling points:', error);
					}
				};

				getSellingPoints();
			} catch (error) {
				console.error('Error fetching typologie:', error);
			}
		};

		getTypologie();
	}, [supervisorValue]);

	return { elements, sellingPoints };
} 