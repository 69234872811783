import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext(null);

export const useAuthContext = () => useContext(AuthContext);

export const Auth = ({ children }) => {
  const navigate = useNavigate();
  const [role, setRole] = useState("");

  const logout = () => {
    localStorage.removeItem('bo-token');
    navigate('/');
  };

  const isAuthorized = () => {
    const token = localStorage.getItem('bo-token');
    if (token) {
      return true;
    }
    return false;
  };

  const getRole = async (token) => {
    let access = "";
      try {
        const res = await axios.get(`${process.env.API_PROTOCOL}://${process.env.API_URL}/users/me?populate=role`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        access = res.data.role.type;
        setRole(access);
      } catch (error) {
        console.error("Error fetching role !!", error);
      }
  }

  useEffect(() => {
    getRole(localStorage.getItem("bo-token"));
  });

  return (
    <AuthContext.Provider value={{ isAuthorized, logout, role }}>{children}</AuthContext.Provider>
  );
};
