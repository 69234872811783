import moment from "moment";

export function ticketsWon(data, paid, t) {
	const tickets = [];

	if (data) {
		data.map((ticket) => {
			
			// if (ticket && ticket.attributes && ticket.attributes.issuer && ticket.attributes.issuer.data && ticket.attributes.issuer.data.attributes) {
				if (ticket.attributes.status === 'win') {
					const TicketId = ticket.attributes.uid.split('-')[0] || '';
					const TicketSeller = ticket?.attributes?.issuer?.data?.attributes?.username || '';
					const TicketStatus = (ticket?.attributes?.paid ? "paid" : "unpaid") || '';
					const TicketDate = ticket.attributes.paidAt ? moment(ticket?.attributes?.paidAt).format('DD MMM YYYY HH:mm') : '-';
					const TicketProfit = ticket?.attributes?.win || ''
					const TicketSoldBy = ticket?.attributes?.issuer?.data?.attributes?.username || '-'
					const TicketPaidBY = ticket?.attributes?.paidBy?.data?.attributes?.username || '-'

					if (paid === "All" || (paid !== "All" && TicketStatus === paid)) {
						tickets.push({
							id: TicketId,
							uid: TicketSeller,
							status: t(TicketStatus),
							paidAt: TicketDate,
							profit: TicketProfit,
							soldBy: TicketSoldBy,
							paidBy: TicketPaidBY
						})
					}
				}
			// }
		})
	}
	return tickets;
}