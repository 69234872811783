import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { Badge } from '@chakra-ui/react';

export function getStatus(status) {
  switch (status) {
    case 'lost':
      return <Badge colorScheme="red">LOST</Badge>;
    case 'win':
      return <Badge colorScheme="green">WIN</Badge>;
    case 'cancelled':
      return <Badge variant="outline">CANCELED</Badge>;
    default:
      return '-';
  }
}

export async function transformTicketResponse(allDataFinal) {
  return allDataFinal.map((ticket) => {
    return {
      id: ticket.id,
      uid: ticket.attributes.uid.split('-')[0],

      type: ticket.attributes.type,
      bets: ticket.attributes.bets.join(' - '),
      status:
        ticket.attributes.type === 'table'
          ? getStatus(ticket.attributes.status)
          : ticket.attributes.status,
      win: ticket.attributes.win,
      paidBy: ticket.attributes.paidBy?.data?.attributes.username,
      paidAt: ticket.attributes.paidAt
        ? moment(ticket.attributes.paidAt).format('DD MMM YYYY HH:mm')
        : '-',
      soldBy: ticket.attributes?.issuer?.data?.attributes?.username,
      createdAt: moment(ticket.attributes.createdAt).format('DD MMM YYYY HH:mm'),
      updatedAt: moment(ticket.attributes.updatedAt).format('DD MMM YYYY HH:mm'),
      printed: ticket.attributes.printed ? "IMPRIME" : "NON IMPRIME",
      printedAt: ticket.attributes.printedAt ? moment(ticket.attributes.printedAt).format('DD MMM YYYY HH:mm') : '-'
    };
  });
}

export async function transformTicketWon(allDataFinal, paid, origin, t) {
  // First create a Set to track unique ticket IDs
  const uniqueTickets = new Set();
  
  return allDataFinal
    .filter((ticket) => {
      if (!ticket || !ticket.attributes) return false;

      // Check for duplicates using ticket ID
      if (uniqueTickets.has(ticket.attributes.uid)) return false;
      uniqueTickets.add(ticket.attributes.uid);

      const isPaid = ticket.attributes.paid;
      const isWin = ticket.attributes.status === 'win';

      // Filtering by payment status
      if (paid === 'Paid' || paid === 'Payé') return isWin && isPaid;
      if (paid === 'All') return isWin;

      return isWin;
    })
    .filter((ticket) => {
      if (!ticket?.attributes?.issuer?.data?.attributes?.username) return false;
      
      // Filtering by origin
      const username = ticket.attributes.issuer.data.attributes.username;
      if (origin === 'USSD') return username === 'USSD';
      if (origin === 'Retail') return username !== 'USSD';
      return true; // If no origin filter specified
    })
    .map((ticket) => {
      if (!ticket?.attributes?.issuer?.data?.attributes) return null;

      return {
        id: ticket.id,
        uid: ticket.attributes.uid.split('-')[0],
        status: t(ticket.attributes.paid ? 'paid' : 'unpaid'),
        paidAt: ticket.attributes.paidAt 
          ? moment(ticket.attributes.paidAt).format('DD MMM YYYY HH:mm') 
          : '-',
        profit: ticket.attributes.win,
        soldBy: ticket.attributes.issuer?.data?.attributes?.username || '-',
        paidBy: ticket.attributes.paidBy?.data?.attributes?.username || '-',
      };
    })
    .filter(Boolean); // Remove null entries
}
