import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { Badge } from '@chakra-ui/react';

export function getStatus(status) {
  switch (status) {
    case 'lost':
      return <Badge colorScheme="red">LOST</Badge>;
    case 'win':
      return <Badge colorScheme="green">WIN</Badge>;
    case 'cancelled':
      return <Badge variant="outline">CANCELED</Badge>;
    default:
      return '-';
  }
}

export async function transformTicketResponse(allDataFinal) {
  return allDataFinal.map((ticket) => {
    return {
      id: ticket.id,
      uid: ticket.attributes.uid.split('-')[0],

      type: ticket.attributes.type,
      bets: ticket.attributes.bets.join(' - '),
      status:
        ticket.attributes.type === 'table'
          ? getStatus(ticket.attributes.status)
          : ticket.attributes.status,
      win: ticket.attributes.win,
      paidBy: ticket.attributes.paidBy?.data?.attributes.username,
      paidAt: ticket.attributes.paidAt
        ? moment(ticket.attributes.paidAt).format('DD MMM YYYY h:mm a')
        : '-',
      soldBy: ticket.attributes?.issuer?.data?.attributes?.username,
      createdAt: moment(ticket.attributes.createdAt).format('DD MMM YYYY h:mm a'),
      updatedAt: moment(ticket.attributes.updatedAt).format('DD MMM YYYY h:mm a'),
      printed: ticket.attributes.printed ? "IMPRIME" : "NON IMPRIME"
    };
  });
}

export async function transformTicketWon(allDataFinal, paid, origin, t) {
  return allDataFinal
    .filter((ticket) => {
      if (!ticket || !ticket.attributes) return false;

      const isPaid = ticket.attributes.paid;

      // Filtering by payment status
      if (paid === 'paid') return ticket.attributes.status === 'win' && isPaid;
      if (paid === 'unpaid') return ticket.attributes.status === 'win' && !isPaid;

      return ticket?.attributes.status === 'win';
    })
    .filter((ticket) => {
      // Filtering by origin
      const username = ticket?.attributes?.issuer?.data?.attributes?.username;
      if (origin === 'USSD') return username === 'USSD';
      else if (origin === 'Retail') return username !== 'USSD';
    })
    .map((ticket) => {
      if (
        !ticket ||
        !ticket.attributes ||
        !ticket.attributes.issuer ||
        !ticket.attributes.issuer.data ||
        !ticket.attributes.issuer.data.attributes
      ) {
        return null;
      }

      return {
        id: ticket.id,
        uid: ticket.attributes.issuer.data.attributes.username,
        status: t(ticket.attributes.paid ? 'paid' : 'unpaid'),
        date: moment(ticket.attributes.createdAt).format('DD MMM YYYY h:mm a'),
        profit: ticket.attributes.win,
      };
    })
    .filter((ticket) => ticket !== null); // Remove null tickets
}
