import React, { useState, useRef, useEffect } from 'react';
import { Box, Heading, Checkbox, Divider, Flex, Button, Alert, AlertIcon, AlertTitle, AlertDescription, List, ListItem, CloseButton, Text, Stack, Card, CardHeader, CardBody, Select, Badge } from '@chakra-ui/react';
import AsyncSelect from './AsyncSelect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDisclosure } from '@chakra-ui/react';

const Importation = () => {
  const modeles = [
    {
      label: 'User',
      value: 'user',
      download: 'user.xlsx',
      path:'/User.xlsx'
    },
    {
      label: 'City',
      value: 'city',
      download: 'city.xlsx',
      path:'/City.xlsx'
    },
    {
      label: 'Country',
      value: 'country',
      download: 'country.xlsx',
      path:'/Country.xlsx'
    },
    {
      label: 'Region',
      value: 'region',
      download: 'region.xlsx',
      path:'/Region.xlsx'
    },
    {
      label: 'Selling Point',
      value: 'selling-point',
      download: 'sellingPoint.xlsx',
      path:'/SellingPoint.xlsx'
    },
    {
      label: 'Zone',
      value: 'zone',
      download: 'zone.xlsx',
      path:'/Zone.xlsx'
    }
  ];
  const { t, i18n } = useTranslation();
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [option, setOption] = useState('city');
  const [loading, setLoading] = useState(false);
  const [format, setFormat] = useState('csv');
  const [importResults, setImportResults] = useState(null);
  const { isOpen: isResultsOpen, onClose: onResultsClose, onOpen: onResultsOpen } = useDisclosure();

  useEffect(() => {
    return () => {
      resetImportState();
    }
  }, []);

  const resetImportState = () => {
    setFile(null);
    setImportResults(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImportResults(null);
      toast.success(t('toasts.fileSelected'));
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click(); // Programmatically trigger file input click
  };

  const handleOnClick = async () => {
    if (!file) {
      toast.error(t('toasts.noFile'));
      return;
    }

    if (!option) {
      toast.error(t('toasts.noModel'));
      return;
    }

    setLoading(true);
    setImportResults(null);

    try {
      const formData = new FormData();
      formData.append('model', option);
      formData.append('format', format);
      formData.append('file', file);

      const res = await axios.post(
        `${process.env.API_PROTOCOL}://${process.env.API_URL}/import`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bo-token")}`,
          },
        }
      );

      console.log("Response", res)

      setImportResults(res.data);
      onResultsOpen();

      // Check the response status and summary
      if (res.status === 200 && res.data.summary.created === res.data.summary.total) {
        toast.success(t('toasts.importSuccess'));
        
      } else if (res.data.summary.failed > 0 || res.data.summary.skipped > 0) {
        // Don't show error toast for partial success
        toast.warning(t('toasts.importPartial'));
      }

    } catch (error) {
      console.log("Error", error)

      if (!error.response || error.response.status !== 200) {
        toast.error(t('toasts.importError'));
      }
      
      const errorData = error.response?.data;
      if (errorData) {
        setImportResults(errorData);
        onResultsOpen();
      }
    } finally {
      setLoading(false);
    }
  };

  const selectedModel = modeles.find((modele) => modele.value === option);

  const renderResults = () => {
    if (!importResults) return null;

    return (
      <Box
        bg="orange.50"
        p={4}
        borderRadius="md"
        position="relative"
        borderLeft="4px"
        borderColor="orange.400"
      >
        {/* Header with Icon and Close Button */}
        <Flex justify="space-between" align="center" mb={3}>
          <Flex align="center" gap={2}>
            <Text fontSize="xl" color="orange.500">⚠️</Text>
            <Text fontSize="lg" fontWeight="600" color="gray.800">
              {importResults.messages[i18n.language]}
            </Text>
          </Flex>
          <Button
            size="sm"
            variant="ghost"
            onClick={() => setImportResults(null)}
            p={0}
            minW="auto"
            height="auto"
            fontSize="lg"
            color="gray.500"
            _hover={{ color: "gray.700" }}
          >
            ×
          </Button>
        </Flex>

        {/* Summary Stats */}
        <Stack spacing={1} mb={4}>
          <Text color="gray.700">
            {t('totalRecords')}: {importResults.summary.total}
          </Text>
          <Text color="green.600">
            {t('recordsCreated')}: {importResults.summary.created}
          </Text>
          <Text color="orange.600">
            {t('recordsSkipped')}: {importResults.summary.skipped}
          </Text>
          <Text color="red.600">
            {t('recordsFailed')}: {importResults.summary.failed}
          </Text>
        </Stack>

        {/* Details Section */}
        {importResults.details && importResults.details.length > 0 && (
          <Box>
            <Text fontWeight="600" color="gray.800" mb={2}>
              Détails:
            </Text>
            <Stack spacing={2}>
              {importResults.details.map((detail, index) => (
                <Text 
                  key={index} 
                  color={detail.status === 'skipped' ? 'orange.600' : 'red.600'} 
                  fontSize="sm"
                >
                  {detail.messages[i18n.language]}
                </Text>
              ))}
            </Stack>
          </Box>
        )}
      </Box>
    );
  };

  const handleModelChange = (e) => {
    setOption(e.target.value);
  };

  return (
    <Box maxW="container.xl" mx="auto" p={6}>
      <Card>
        <CardHeader pb={4}>
          <Heading size="md">{t('importData')}</Heading>
        </CardHeader>
        
        <CardBody>
          {/* Results Alert */}
          {renderResults()}

          <Stack spacing={6}>
            {/* Model Selection */}
            <Box>
              <Text mb={2} fontWeight="500">
                {t('selectModel')}
              </Text>
              <Select
                placeholder={t('selectModelPlaceholder')}
                value={option}
                onChange={handleModelChange}
                bg="white"
              >
                {modeles.map((model) => (
                  <option key={model.value} value={model.value}>
                    {t(model.value)}
                  </option>
                ))}
              </Select>
            </Box>

            {/* Warning Note */}
            <Box 
              p={4} 
              bg="red.50" 
              borderRadius="md"
              borderLeft="4px"
              borderColor="red.400"
            >
              <Text 
                color="red.500" 
                fontWeight="600" 
                mb={2}
                fontSize="15px"
              >
                **REMARQUE**
              </Text>
              <Text 
                color="gray.700"
                fontSize="14px"
                lineHeight="1.6"
              >
                {t('existingValuesNote')}
              </Text>
            </Box>

            {/* Roles */}
            <Box 
              bg="blue.50" 
              p={4} 
              borderRadius="md"
              border="1px"
              borderColor="blue.200"
            >
              <Text 
                fontWeight="600" 
                mb={3}
                color="gray.700"
              >
                {t('availableRoles')}:
              </Text>
              <Stack direction="row" spacing={3} wrap="wrap">
                <Badge
                  px={3}
                  py={1}
                  borderRadius="full"
                  bg="blue.100"
                  color="blue.700"
                  textTransform="none"
                  fontFamily="monospace"
                >
                  Admin
                </Badge>
                <Badge
                  px={3}
                  py={1}
                  borderRadius="full"
                  bg="blue.100"
                  color="blue.700"
                  textTransform="none"
                  fontFamily="monospace"
                >
                  Agent
                </Badge>
                <Badge
                  px={3}
                  py={1}
                  borderRadius="full"
                  bg="blue.100"
                  color="blue.700"
                  textTransform="none"
                  fontFamily="monospace"
                >
                  finance
                </Badge>
                <Badge
                  px={3}
                  py={1}
                  borderRadius="full"
                  bg="blue.100"
                  color="blue.700"
                  textTransform="none"
                  fontFamily="monospace"
                >
                  support
                </Badge>
              </Stack>
            </Box>

            {/* Hidden File Input */}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
              accept={format === 'csv' ? '.csv' : '.json'}
            />

            {/* Action Buttons */}
            <Stack 
              direction="row" 
              justify="flex-end" 
              spacing={4}
              pt={4}
              borderTop="1px"
              borderColor="gray.200"
            >
              <Button
                leftIcon={<Box as="span" fontSize="xl">⬇️</Box>}
                variant="outline"
                colorScheme="blue"
                isDisabled={!selectedModel?.path}
                as={selectedModel?.path ? 'a' : 'button'}
                href={selectedModel?.path}
                download={selectedModel?.download}
              >
                {t('downloadExample')}
              </Button>

              <Button
                leftIcon={<Box as="span" fontSize="xl">📁</Box>}
                colorScheme="teal"
                variant="outline"
                onClick={() => fileInputRef.current?.click()}
                isDisabled={loading}
              >
                {file ? file.name : t('import')}
              </Button>

              <Button
                leftIcon={<Box as="span" fontSize="xl">✓</Box>}
                colorScheme="pink"
                isLoading={loading}
                loadingText={t('importing')}
                onClick={handleOnClick}
                isDisabled={!file || !option}
              >
                {t('validate')}
              </Button>
            </Stack>
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
};

export default Importation;
