import { useQuery } from 'react-query';
import request from '@request';
import { convertObjectToSearchParams } from '@utils/convertToSearchParams';

const getTicketsWon = ({
  queryKey: [, { page, agent, endDate, startDate, region, paid, origin }]
}) => {
	let status;

  if (paid === "paid" || paid === "payé")
	  status = true;
  else if (paid === "unpaid" || paid === "non payé")
	  status = false;
    
  const searchParams = {
    populate: 'paidBy,issuer',
    'pagination[page]': page,
    'filters[createdAt][$gte]': startDate,
    'filters[createdAt][$lte]': endDate,
    'filters[issuer][username][$contains]': agent,
	  'filters[win][$gte]': 1,
	  'filters[paid]': status,
    'filters[selling_point][zone][city][region][name][$eq]': region,
    'sort[createdAt]': 'DESC'
  };
  if (origin === "USSD") {
    searchParams['filters[issuer][username][$eq]'] = "USSD";
  } else if (origin === "Retail") {
    searchParams['filters[issuer][username][$ne]'] = "USSD";
  }
  return request.get(`/tickets?${convertObjectToSearchParams(searchParams)}`);
};

export const useTicketsWon = ({
  agent,
  endDate,
  startDate,
  region,
  page,
  pageSize,
  paid,
  origin
}) => {
  const queryParams = {
    populate: 'paidBy,issuer',
    'pagination[page]': page,
    'pagination[pageSize]': pageSize,
    'filters[win][$gte]': 1,
    'filters[printed]': true,
    'sort[paidAt]': 'DESC'
  };

  if (startDate) {
    queryParams['filters[paidAt][$gte]'] = startDate;
  }
  if (endDate) {
    queryParams['filters[paidAt][$lte]'] = endDate;
  }
  if (agent) {
    queryParams['filters[issuer][username][$contains]'] = agent;
  }
  if (region) {
    queryParams['filters[selling_point][zone][city][region][name][$eq]'] = region;
  }

  if (paid === "Paid" || paid === "Payé") {
    queryParams['filters[paid]'] = true;
  } else if (paid === "Unpaid" || paid === "Non payé") {
    queryParams['filters[paid]'] = false;
  }

  if (origin === "USSD") {
    queryParams['filters[issuer][username][$eq]'] = "USSD";
  } else if (origin === "Retail") {
    queryParams['filters[issuer][username][$ne]'] = "USSD";
  }

  return useQuery(['tickets-won', queryParams], () =>
    request.get('/tickets', { params: queryParams })
  );
};
