import { Box, Button, Flex, Image, Link } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import MillionaireLogo from '@assets/Logo-millionnaire.png';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '@context/Auth';
import { useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const {
    t,
    i18n: { changeLanguage, language }
  } = useTranslation();
  const { isAuthorized, logout, role } = useAuthContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      p={2}
      borderBottom="1px"
      borderColor="gray.200"
      minH="70px"
      pos="sticky"
      width="100%">
      <Box>
        <Image src={MillionaireLogo} alt="main logo" height="60px" objectFit="contain" />
      </Box>
      {isAuthorized() && (
        <Flex gap="3em">
          <Link
            color={pathname === '/dashboard' && 'pink.300'}
            onClick={() => navigate('/dashboard')}
            hidden={(role === "finance" || role === "support") ? true : false}
          >
            Dashboard
          </Link>
          <Link
            color={pathname === '/tickets' && 'pink.300'}
            onClick={() => navigate('/tickets')}
            hidden={(role === "finance") ? true : false}
          >
            Tickets
          </Link>
          <Link
            color={pathname === '/agents' && 'pink.300'}
            onClick={() => navigate('/agents')}
            hidden={(role === "finance" || role === "support") ? true : false}
          >
            Agents
          </Link>
          <Link
            color={pathname === '/rapports' && 'pink.300'}
            onClick={() => navigate('/rapports')}
            hidden={(role === "support") ? true : false}
          >
            Rapports
          </Link>
          <Link
            color={pathname === '/import' && 'pink.300'}
            onClick={() => navigate('/import')}
            hidden={(role === "support" || role === "finance") ? true : false}
          >
            Import
          </Link>
        </Flex>
      )}
      <Flex gap="10px">
        <Flex>
          <Button
            borderRightRadius={0}
            variant={language !== 'fr' && 'solid'}
            border={language === 'fr' && '1px'}
            borderColor={'gray'}
            onClick={() => changeLanguage('fr')}>
            Fr
          </Button>
          <Button
            borderLeftRadius={0}
            variant={language !== 'en' && 'solid'}
            border={language === 'en' && '1px'}
            borderColor={'gray'}
            onClick={() => changeLanguage('en')}>
            En
          </Button>
        </Flex>
        {isAuthorized() && (
          <Button variant="ghost" colorScheme="red" onClick={logout} w="10em">
            {t('signin.logout')}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default Navbar;
