import axios from "axios";
import { useEffect, useState } from "react";

export const useTypologie = () => {
	const token = localStorage.getItem("bo-token");
	const [elements, setElements] = useState();
	const [sellingPoints, setSellingPoints] = useState();

	useEffect(() => {
		const getTypologie = async () => {
			try {
				const res = await axios.get(`${process.env.API_PROTOCOL}://${process.env.API_URL}/typologie?populate[elements]=true`, {
					headers: {
					  Authorization: `Bearer ${token}`,
				}});
				setElements(res.data);
			} catch (error) {
				
			}
		}
		const getSellingPoints = async () => {
			try {
				const allSellingPoints = [];
				let page = 1;
				let totalPages = 1;
		
				while (page <= totalPages) {
				  const res = await axios.get(`${process.env.API_PROTOCOL}://${process.env.API_URL}/selling-points`, {
					headers: {
					  Authorization: `Bearer ${token}`
					},
					params: {
					  'pagination[page]': page,
					}
				  });
		
				  allSellingPoints.push(...res.data.data);
				  totalPages = res.data.meta.pagination.pageCount;
				  page += 1;
				}
				setSellingPoints(allSellingPoints);
			} catch (error) {
				
			}
		}
		getTypologie();
		getSellingPoints();
	}, []);
	return {elements, sellingPoints};
} 