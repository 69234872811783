import React from 'react';
import {
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import styles from "./module.table.css"

const DataGrid = ({ isLoading, columns, rows }) => {
  const { t } = useTranslation();

  return (
    <TableContainer maxW="100%">
      <Table size="sm">
        <Thead>
          <Tr>
            {columns && columns.map((column, index) => (
              <Th key={index}>{column.label}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {!isLoading && rows && 
            rows.map((row, index) => (
              <Tr key={index}>
                {row && columns && columns.map(({ key }, index) => (
                  <Td key={index} className={`${row[key] === "Total" ? "table" : ""}`}>{row[key]}</Td>
                ))}
              </Tr>
          ))}
        </Tbody>
      </Table>
      {isLoading && (
        <Flex mt={6} justifyContent="center">
          <Spinner />
        </Flex>
      )}
      {!isLoading && rows && rows.length === 0 && (
        <Flex mt={6} justifyContent="center">
          <Text>{t('noDataFound')}</Text>
        </Flex>
      )}
    </TableContainer>
  );
};

export default DataGrid;
