import { useQuery } from 'react-query';
import request from '@request';
import { convertObjectToSearchParams } from '@utils/convertToSearchParams';

const getTicketsWon = ({
  queryKey: [, { page, agent, endDate, startDate, region, paid }]
}) => {
	let status;

  if (paid === "paid" || paid === "payé")
	status = true;
  else if (paid === "unpaid" || paid === "non payé")
	status = false;
  const searchParams = {
    populate: 'paidBy,issuer',
    'pagination[page]': page,
    'filters[createdAt][$gte]': startDate,
    'filters[createdAt][$lte]': endDate,
    'filters[issuer][username][$contains]': agent,
	'filters[win][$gte]': 1,
	'filters[paid]': status,
    'filters[selling_point][zone][city][region][name][$eq]': region,
    'sort[createdAt]': 'DESC'
  };
  return request.get(`/tickets?${convertObjectToSearchParams(searchParams)}`);
};

export const useTicketsWon = ({ page, agent, endDate, startDate, region, paid }) =>
  useQuery(
    ['tickets', { page, agent, endDate, startDate, region, paid }],
    getTicketsWon
  );
