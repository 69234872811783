import request from '@request';
import { useQuery } from 'react-query';
import { convertObjectToSearchParams } from '@utils/convertToSearchParams';

const getZones = ({ queryKey: [, { search, city, region }] }) => {
  const searchParams = {
    'pagination[pageSize]': 900,
    'filters[city][id][$eq]': city,
    'filters[city][region][name][$eq]': region,
    _q: search
  };
  return request.get(`/zones?${convertObjectToSearchParams(searchParams)}`);
};
const getCities = ({ queryKey: [, { search, region }] }) => {
  const searchParams = {
    'pagination[pageSize]': 900,
    'filters[region][name][$eq]': region,
    _q: search
  };
  return request.get(`/cities?${convertObjectToSearchParams(searchParams)}`);
};

const getRegions = ({ queryKey: [, {}] }) => {
  const searchParams = {
    'pagination[pageSize]': 900
  };
  return request.get(`/regions?${convertObjectToSearchParams(searchParams)}`);
};

export const useRegions = () => useQuery(['regions', {}], getRegions);
export const useCities = ({ search, region }) =>
  useQuery(['cities', { search, region }], getCities);
export const useZones = ({ search, city, region }) =>
  useQuery(['zones', { search, city, region }], getZones);
