import React, { useState, useRef, useEffect } from 'react';
import { Box, Heading, Checkbox, Divider, Flex, Button } from '@chakra-ui/react';
import AsyncSelect from './AsyncSelect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from 'axios';

const Importation = () => {
  const modeles = [
    {
      label: 'User',
      value: 'user',
      download: 'user.xlsx',
      path:'/User.xlsx'
    },
    {
      label: 'City',
      value: 'city',
      download: 'city.xlsx',
      path:'/City.xlsx'
    },
    {
      label: 'Country',
      value: 'country',
      download: 'country.xlsx',
      path:'/Country.xlsx'
    },
    {
      label: 'Region',
      value: 'region',
      download: 'region.xlsx',
      path:'/Region.xlsx'
    },
    {
      label: 'Selling Point',
      value: 'selling-point',
      download: 'sellingPoint.xlsx',
      path:'/SellingPoint.xlsx'
    },
    {
      label: 'Zone',
      value: 'zone',
      download: 'zone.xlsx',
      path:'/Zone.xlsx'
    }
  ];
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [option, setOption] = useState('city');
  const [loading, setLoading] = useState(false);
  const [format, setFormat] = useState('csv');

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      toast.success('File selected succussfully');
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click(); // Programmatically trigger file input click
  };

  const handleOnClick = async () => {
    if (!file) {
      toast.error('Please select a file to import');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('model', option);
      formData.append('format', format);
      formData.append('file', file);

      const res = await axios.post(
        `${process.env.API_PROTOCOL}://${process.env.API_URL}/import`,
        formData
          , {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bo-token")}`,
            "Content-Type": "multipart/form-data",
          },
        },
      );
      toast.success('Importation Succeeded');
    } catch (error) {
      toast.error('Importation Failed');
    }
  };

  // useEffect(() => {
  //   if (file) toast.success('File selected succussfully');
  // }, [file]);

  const selectedModel = modeles.find((modele) => modele.value === option);

  return (
    <Box w={'100%'} display={'flex'} flexDirection={'column'} gap={'32px'}>
      <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
        <Heading fontWeight={'semibold'} size={'md'}>
          Choisir un modele
        </Heading>
        <Box w={'25%'}>
          <AsyncSelect data={modeles} value={option} setValue={setOption} />
        </Box>
        {option && (
          <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
            <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
              <p style={{color: "red"}}>**{t('notice')}**</p>
              <p>
                {t('importInfo')}
              </p>
            </Box>
            <Divider />
            <p style={{ fontWeight: 'bold' }}>Roles: Admin, Agent, finance, support</p>
          </Box>
        )}
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
        <Flex justifyContent={'flex-end'} gap={'20px'}>
          <div style={{border: '2px solid lightblue', display: "flex", alignItems: "center", padding: "0 8px", borderRadius: "4px", color: "lightblue"}} >
            <a href={selectedModel.path} download={selectedModel.download}>
              <button >{t('downloadExample')}</button>
            </a>
          </div>
          <input
            style={{ display: 'none' }}
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            accept={format === 'csv' ? '.csv' : '.json'}
          />
          <Button
            colorScheme="teal"
            variant="outline"
            disabled={loading}
            isLoading={loading}
            loadingText="Importing"
            spinnerPlacement="start"
            onClick={handleFileButtonClick}>
            {t('import')}
          </Button>
          <Button
            colorScheme="pink"
            variant="outline"
            disabled={loading}
            isLoading={loading}
            loadingText="Importing"
            spinnerPlacement="start"
            onClick={handleOnClick}>
            {t('validate')}
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default Importation;
