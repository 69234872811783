import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutateLogin } from '@store/server/login/mutations';
import { useAuthContext } from '@context/Auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Signin = () => {
  const [show, setShow] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();
  const { mutate, isLoading, data, error, isError, isSuccess } = useMutateLogin();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getRole = async (token) => {
    try {
      const res = await axios.get(`${process.env.API_PROTOCOL}://${process.env.API_URL}/users/me?populate=role`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      let access = res.data.role.type;
      // localStorage.setItem('role', res.data.role.type);
      localStorage.setItem('bo-token', token);
      if (access === "finance")
        navigate('/rapports');
      else if (access === "support")
        navigate("/tickets");
      else
        navigate('/dashboard');
    } catch (error) {
      console.error("Error fetching role !!", error);
    }
  }

  if (isSuccess) {
    getRole(data.data.jwt);
    // login(data.data.jwt);
  } 

  const handleToggleShowPassword = () => setShow((show) => !show);

  const onSubmit = ({ email, password }) => {
    mutate({
      identifier: email,
      password
    });
  };

  return (
    <Flex justifyContent="center" alignItems="center" minH="xl" w="100%">
      <Container
        border="1px"
        borderStyle="solid"
        borderColor="gray.300"
        p={3}
        minW="md"
        borderRadius="md">
        {isError && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{error?.response?.data?.error.message}</AlertDescription>
          </Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing="24px" direction="column">
            <Flex justifyContent="center" p={3}>
              <Heading size="md">{t('signin.login')}</Heading>
            </Flex>
            <Box>
              <FormControl isInvalid={errors?.email}>
                <Input
                  type="email"
                  name="email"
                  {...register('email', { required: t('errors.emailRequired') })}
                  errorBorderColor="red.300"
                  placeholder="E-mail"
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
            </Box>
            <Box>
              <FormControl isInvalid={errors?.password}>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={show ? 'text' : 'password'}
                    placeholder={t('signin.password')}
                    {...register('password', { required: t('errors.passwordRequired') })}
                  />
                  <InputRightElement width="6rem">
                    <Button h="1.75rem" size="sm" onClick={handleToggleShowPassword}>
                      {show ? t('signin.hide') : t('signin.show')}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>
            </Box>
            <Flex justifyContent="center">
              <Button isLoading={isLoading} type="submit" colorScheme="pink">
                {t('signin.submit')}
              </Button>
            </Flex>
          </Stack>
        </form>
      </Container>
    </Flex>
  );
};

export default Signin;
