import React from 'react';
import { Select } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Filter = ({ value, setValue, data }) => {
  const { t } = useTranslation();

  return (
    <Select width={'100%'} value={value} onChange={(e) => setValue(e.target.value)}>
      {data &&
        data.map((e, key) => (
          <option key={key} value={e}>
            {t(e)}
          </option>
        ))}
    </Select>
  );
};
export default Filter;
